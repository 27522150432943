<template>
    <div>
        <strong>Part Upload</strong>
        <br>
        <div class="form-group">
            <label>Part</label>
            <Typeahead ref="partFileHandlerPart" cdi_type="part" v-bind:ID.sync="state.referenceID" />
        </div>
        <div class="form-group">
            <label>File Type</label>
            <select v-model="state.fileTypeID" class="form-control">
                <option :value="$cdiVars.FILE_TYPE_TWOD_DRWNG">2D Drawing</option>
                <option :value="$cdiVars.FILE_TYPE_PART_ENGINEERING_DETAIL">Part Engineering Detail</option>
            </select>
        </div>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import Typeahead from "@/components/utils/typeaheads/Typeahead";

    export default {
        name: "Part",
        components: {
            Typeahead
        },
        data() {
            return {
                state: fileUploadStore.state
            }
        },
        created() {
            fileUploadStore.initialize();
        },
        mounted() {
            this.$refs.partFileHandlerPart.$data.object = this.$appStore.state.fileUploadContextData.data.cdi_part_num;
            this.state.fileTypeID = this.$cdiVars.FILE_TYPE_TWOD_DRWNG;
        }
    }
</script>

<style scoped>

</style>